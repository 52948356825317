import { faWordpress } from '@fortawesome/free-brands-svg-icons';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { SiteListItem } from 'api/site';
import { useExternalLink } from 'component/hooks/useExternalLink';
import { SiteLockedPopover } from 'component/partial/SiteLockedPopover';
import { getSiteLockedStatus } from 'utils/site';

interface Props {
  readonly site: SiteListItem;
}

export const WPAdminCell = ({ site }: Props) => {
  const { openExternalLink } = useExternalLink();
  const isSiteStatic = site.site_type === 1;
  const isSiteLocked = getSiteLockedStatus(site);

  return isSiteStatic ? (
    '- '
  ) : (
    <SiteLockedPopover isSiteLocked={isSiteLocked}>
      <Button
        onClick={() =>
          openExternalLink({
            iframeUrl: `/manage/${site.id}/wordpress?token=${window.sessionStorage.getItem('user_session')}`,
            url: `/manage/${site.id}/wordpress}`,
          })
        }
        variant="text"
        color="primary"
        startIcon={<FontAwesomeIcon icon={faWordpress} />}
        endIcon={<FontAwesomeIcon icon={faLongArrowRight} />}
        disabled={isSiteLocked}
      >
        {''}
      </Button>
    </SiteLockedPopover>
  );
};
