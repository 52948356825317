import { Edit } from '@mui/icons-material';
import { Button, Typography, Box } from '@mui/material';
import { SiteListItem } from 'api/site';
import { ChangeLabelTooltip } from 'component/partial/ChangeLabelTooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  readonly site: SiteListItem;
}

export const LabelCell = ({ site }: Props) => {
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {!!isEditPopupOpen && (
        <ChangeLabelTooltip site={site} onClose={() => setIsEditPopupOpen(false)} />
      )}
      <Button variant="text" color="primary" onClick={() => setIsEditPopupOpen(true)}>
        {site.label ? (
          <>
            <Typography color="textPrimary">{site.label}</Typography>
            <Box display="inline-block" marginLeft={1}>
              <Edit />
            </Box>
          </>
        ) : (
          <Typography color="textSecondary">{t('add_label')}</Typography>
        )}
      </Button>
    </>
  );
};
