import { faComments, faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { SiteListItem } from 'api/site';
import { useExternalLink } from 'component/hooks/useExternalLink';

interface Props {
  readonly site: SiteListItem;
}

export const AtarimCell = ({ site }: Props) => {
  const { openExternalLink } = useExternalLink();

  return (
    <Button
      onClick={() =>
        openExternalLink({
          url: `https://app.atarim.io/fetching/?_from=rocket&url=https://${site.domain}`,
        })
      }
      variant="text"
      color="primary"
      startIcon={<FontAwesomeIcon icon={faComments} />}
      endIcon={<FontAwesomeIcon icon={faLongArrowRight} />}
    >
      {''}
    </Button>
  );
};
