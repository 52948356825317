import { SiteListItem } from 'api/site';
import CountryName from 'component/base/CountryName';

interface Props {
  readonly site: SiteListItem;
}

export const LocationCell = ({ site }: Props) => {
  const location = site.location?.split(' - ') ?? '';

  return <CountryName countryCode={location[0]} size="s" />;
};
