import { faWordpress } from '@fortawesome/free-brands-svg-icons';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { SiteListItem } from 'api/site';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
  readonly site: SiteListItem;
}

export const ManageCell = ({ site }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Button
      onClick={() => navigate(`/sites/${site.id}`)}
      variant="text"
      color="primary"
      endIcon={<FontAwesomeIcon icon={faLongArrowRight} />}
    >
      {t('manage')}
    </Button>
  );
};
