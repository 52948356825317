import { useContext } from 'react';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { Skeleton } from '@mui/material';
import { SiteListItem, useSitesNew, useSitesUsersNew } from 'api/site';
import { useProfile } from 'api/profile';
import { formatUsage } from 'utils/usage';
import { useTranslation } from 'react-i18next';
import { getIsWpSiteControl } from 'utils';
import { TableColumnType } from 'component/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { Table } from 'component/base/Table';
import { LabelCell } from './LabelCell';
import { LocationCell } from './LocationCell';
import { AtarimCell } from './AtarimCell';
import { WPAdminCell } from './WPAdminCell';
import { ManageCell } from './ManageCell';

interface Props {
  readonly enablePagination?: boolean;
  readonly enableSearch?: boolean;
  readonly children?: React.ReactNode;
}

const useIsAtarimEnabled = () => {
  const { getSdk } = useContext(PostMessageContext);
  const { data: profileData } = useProfile();

  const packagePlan = profileData?.data.result?.package?.plan;

  return Boolean(getSdk() === null && packagePlan && packagePlan.toLowerCase() !== 'starter');
};

export function SitesTable({ children, enablePagination, enableSearch }: Props) {
  const { t } = useTranslation();
  const isWpSiteControl = getIsWpSiteControl();
  const showAtarim = useIsAtarimEnabled();

  const tableState = usePaginatedListState();

  const sitesNew = useSitesNew(tableState.params, { enabled: !isWpSiteControl });
  const sitesUserNew = useSitesUsersNew(tableState.params, { enabled: isWpSiteControl });

  const { data, isLoading } = isWpSiteControl ? sitesUserNew : sitesNew;

  const columns: (TableColumnType<SiteListItem> | null)[] = [
    {
      label: t('domain'),
      key: 'domain',
      sortable: true,
    },
    {
      label: t('label'),
      renderValue: site => <LabelCell site={site} />,
      key: 'label',
      sortable: true,
    },
    {
      label: t('disk_usage'),
      renderValue: site => formatUsage(site.disk_usage),
      key: 'disk_usage',
      sortable: true,
    },
    {
      label: t('location'),
      renderValue: site => <LocationCell site={site} />,
      renderLoader: () => <Skeleton width={120} />,
    },
    showAtarim ? { label: t('atarim'), renderValue: site => <AtarimCell site={site} /> } : null,
    {
      label: t('wp_admin'),
      renderValue: site => <WPAdminCell site={site} />,
      renderLoader: () => <Skeleton width={64} />,
    },
    {
      label: t('action'),
      renderValue: site => <ManageCell site={site} />,
      renderLoader: () => <Skeleton width={64} />,
    },
  ];

  return (
    <Table
      title={t('sites')}
      label="Site List Table"
      columns={columns}
      data={data?.data.result || []}
      totalRowCount={data?.data.metadata?.total || 0}
      isLoading={isLoading}
      state={tableState}
      searchPlaceholder={t('search_sites_label')}
      enableSearch={enableSearch}
      enablePagination={enablePagination}
    >
      {children}
    </Table>
  );
}
